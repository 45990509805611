import Hero from "../Components/Majors/Hero";
import ProductUpdate from "../Components/Micro/ProductUpdate";
import FaqSection from "../Components/Micro/FaqSection";
import { TagLine } from "@gogeepernpm/storybook/lib";
import { useGlobalAuth } from "../hooks/useGlobalAuth";

const Homepage = () => {
  const { triggerSignUp } = useGlobalAuth();
  return (
    <>
      <Hero />
      <ProductUpdate />
      <FaqSection />
      <TagLine
        handleAction={triggerSignUp}
        primaryText={"Still have questions?"}
        secondaryText={
          "Can’t find the answer you’re looking for? Talk to our team."
        }
        btnText={"Get in touch"}
      />
    </>
  );
};

export default Homepage;

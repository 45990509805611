import React from "react";
import MicrolendingFaq from "../Supports/microlendingFaq";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Menu,
  MenuButton,
  Text,
  MenuItem,
  Flex,
  MenuList,
} from "@chakra-ui/react";
import ContainerLayout from "../../Layouts/ContainerLayout";
import FaaMs from "../Supports/FaaMs";
import { FaCaretDown } from "react-icons/fa";
import Marketplace from "../Supports/Marketplace";
import Foodbank from "../Supports/Foodbank";
import CommodityLinkage from "../Supports/CommodityLinkage";

function FaqSection() {
  return (
    <>
      <ContainerLayout>
        {/* @ts-ignore */}
        <Tabs orientation={"vertical"} colorScheme={"green"} textAlign={"left"}>
          <Box display={{ base: "block", md: "block", lg: "none" }}>
            <Menu>
              <MenuButton
                border={"1px solid #D0D5DD "}
                py={"0.5em"}
                borderRadius={"8px"}
                w={"100%"}
                px={"1em"}
              >
                <Flex justifyContent={"space-between"}>
                  <Text> All </Text>

                  <FaCaretDown />
                </Flex>
              </MenuButton>
              <MenuList>
                <MenuItem>
                  <Tab _selected={{ color: "green" }}>Marketplace</Tab>{" "}
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Tab _selected={{ color: "green" }}> FAMaaS™ </Tab>{" "}
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Tab _selected={{ color: "green" }}> Foodbank </Tab>{" "}
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Tab _selected={{ color: "green" }}> Microlending </Tab>{" "}
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Tab _selected={{ color: "green" }}> Marketplace </Tab>{" "}
                </MenuItem>
                <MenuItem>
                  {" "}
                  <Tab _selected={{ color: "green" }}>
                    {" "}
                    Commodity Linkage
                  </Tab>{" "}
                </MenuItem>
                {/* <MenuItem>          <Tab _selected={{'color':'green'}}> Insight and Analytics  </Tab> </MenuItem> */}
              </MenuList>
            </Menu>
          </Box>

          <TabList
            textAlign={"left"}
            display={{ base: "none", md: "none", lg: "block" }}
            w={["", "", "20em"]}
          >
            <Tab>Marketplace</Tab>
            <Tab> FAMaaS™</Tab>
            <Tab>Foodbank</Tab>
            <Tab>Microlending</Tab>
            <Tab>Commodity Linkage</Tab>
          </TabList>

          <TabPanels mx={{ base: "", md: "", lg: "5em" }}>
            <TabPanel>
              <Marketplace />
            </TabPanel>
            <TabPanel>
              <FaaMs />
            </TabPanel>
            <TabPanel>
              <Foodbank />
            </TabPanel>
            <TabPanel>
              <MicrolendingFaq />
            </TabPanel>
            <TabPanel>
              <CommodityLinkage />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </ContainerLayout>
    </>
  );
}

export default FaqSection;

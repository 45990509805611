import CreateAccordion from "../Minors/AccordionInstance";
import useFaq from "../../react-query/hooks/useFaq";

function Foodbank() {
  const { data } = useFaq({ category: "foodbank" });
  return (
    <>
      {data?.data?.map(({ question, answers }, index) => {
        return (
          <>
            <CreateAccordion key={index} header={question} answers={answers} />
          </>
        );
      })}
    </>
  );
}

export default Foodbank;

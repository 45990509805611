import "./App.css";
import Homepage from "./pages";
import { Box } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import { Header, ErrorPage, Footer ,Loader } from "@gogeepernpm/storybook/lib";
//@ts-ignore
import AOS from "aos";
import {  useSelector } from "react-redux";
import { IRootReducerState } from "./redux/IRootReducer";
import { useGlobalAuth } from "./hooks/useGlobalAuth";

function App() {
  const { isInitialized } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  const [loader, setLoader] = useState(true);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
    setTimeout(() => setLoader(false), 500);
    // window.onload = () => setTimeout(() => setLoader(false), 500);
  }, []);

  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(-1);

  const handleItemClick = (index: number) =>
    activeIndex === index ? setActiveIndex(-1) : setActiveIndex(index);
  const toggleSidebar = () => setOpen(!open);

  const contact = () => window.Tawk_API.maximize();
  useEffect(() => {
    // dispatch(INITIALIZE());
  }, [isInitialized]);

  const { triggerLogout, triggerSignUp, triggerlogin } = useGlobalAuth();

  const { isAuthenticated } = useSelector(
    (state: IRootReducerState) => state.auth
  );

  return (
    <div className="App">
      <Box display={loader ? "block" : "none"}>
        <Loader loading={loader} />
      </Box>
      <Header
        sidebarOpen={open}
        toggleSidebarOpen={toggleSidebar}
        activeIndex={activeIndex}
        onClick={handleItemClick}
        isLoggedIn={isAuthenticated}
        triggerLogin={triggerlogin}
        triggerSignup={triggerSignUp}
      />

      <Router>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="*"
            element={<ErrorPage onContact={contact} onSignUp={triggerSignUp} />}
          />
        </Routes>
      </Router>
      <Footer onTalkToUsClick={contact} />
    </div>
  );
}

export default App;

import CreateAccordion from "../Minors/AccordionInstance";
import useFaq from "../../react-query/hooks/useFaq";

const CommodityLinkage = () => {
  const { data } = useFaq({ category: "commodity_linkage" });
  return (
    <>
      {data?.data?.map(({ question, answers }, index) => {
        return (
          <>
            <CreateAccordion key={index} header={question} answers={answers} />
          </>
        );
      })}
    </>
  );
};

export default CommodityLinkage;
